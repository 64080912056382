import * as React from "react"
import loadable from "@loadable/component";
import MainLayout from '../layouts/main'
import {useContext, useState} from "react";
import GlobalContext from "../context/GlobalContext";
import {graphql, useStaticQuery} from "gatsby";
import Breadcrumps from "../components/Breadcrumbs";
import HeroPage from "../components/HeroPage";
import SeoComponent from "../layouts/partials/SeoComponent";
const Form = loadable(() => import('./contatti/form'))


const items = [
    { title: 'Contatti' }
]
const Contatti = ({pageContext}) => {
    const gContext = useContext(GlobalContext);
    const [sent, setSent] = useState(false)
    const adwPhone = () => {
        if(window.gtag) {
            window.gtag('config', 'AW-978530713/bf7DCKeRofsDEJnjzNID', {
                'phone_conversion_number': '050.40923'
            });
        }
    }
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(contatti)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]         
                        placeholder: NONE      
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    return (
        <MainLayout pageContext={pageContext}>
            <HeroPage
                image={gContext.getImageFromName(images, 'contatti.jpg')}
                imageAlt={'contatti.jpg'}
                title={'Contattaci'}
                overlay={true}
            />

            <Breadcrumps  items={items}/>

            <div className="max-w-[1280px] mx-auto py-16 px-4 sm:px-6 lg:px-8 relative w-full">
                <div className="mb-10 flex items-center justify-center w-full">
                    <h2 className="z-10 flex flex-col items-center justify-center text-center font-bold text-xl sm:text-3xl leading-none uppercase w-full max-w-[660px]">{gContext.t('Contattaci per maggiori informazioni o per un preventivo gratuito!')}</h2>
                </div>
                <div className="text-lg sm:text-2xl uppercase border-b border-black pb-1 mb-6">
                    {gContext.t('Contatti diretti')}
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 leading-6">
                    <div className="lg:col-span-5 text-black/60">
                        <p className="lg:max-w-[350px]">{gContext.t('Contattaci direttamente negli orari di apertura il nostro staff è a tua disposizione.')}</p>
                    </div>
                    <div className="lg:col-span-7">
                        <p>Tel: <a href="tel:05040923" onClick={adwPhone} className="font-bold hover:underline">050.40923</a></p>
                        <p>E-mail: <a href="mailto:info@tappezzeriaorisllisrl.com" className="font-bold hover:underline">info@tappezzeriaorsillisrl.com</a></p>
                    </div>
                    <div className="lg:col-span-5 text-black/60" dangerouslySetInnerHTML={{ __html: gContext.t('contatti.indirizzo') }}></div>
                    <div className="lg:col-span-7 border-0">
                        <iframe title="maps" className="h-[450px] w-full" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11535.055834121178!2d10.3935159!3d43.7154542!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4ebecc971751bb93!2sTappezzeria%20Orsilli!5e0!3m2!1sit!2sit!4v1654776074166!5m2!1sit!2sit" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
                    </div>
                </div>
                <div className="text-lg sm:text-2xl uppercase border-b border-black pb-1 mb-6 mt-8">
                    {gContext.t('Compila il form')}
                </div>
                <div className="relative grid grid-cols-1 lg:grid-cols-12 gap-6 leading-6">
                    <div className={`absolute inset-0 bg-white z-10 lg:col-span-12 text-center flex flex-col items-center justify-center p-4 sm:p-8 text-xl sm:text-2xl ${sent ? '' : 'hidden'}`}>
                        <p className="font-bold text-2xl sm:text-3xl">Richiesta inviata correttamente!</p>
                        <p>Ti risponderemo il prima possibile,</p>
                        <p>grazie per averci contattato.</p>
                        <img className="w-auto h-10 sm:h-12 lg:h-16 my-6" src={'/logo.svg'} alt="Orsilli" width="197" height="72"/>
                        <button type="button" onClick={() => {
                            setSent(false)
                        }} className="inline-flex items-center justify-center text-center text-base uppercase font-semibold min-h-[50px] py-2 px-3 w-full rounded-md bg-black text-white hover:bg-primary hover:text-black focus:outline-none focus:ring-0 transition duration-200 ease-in-out max-w-[150px]">
                            {gContext.t('Chiudi')}
                        </button>
                    </div>

                    <div className="lg:col-span-5 text-black/60">
                        <p className="lg:max-w-[350px]">{gContext.t('Oppure compila il form, ti risponderemo il prima possibile.')}</p>
                    </div>
                    <div className="lg:col-span-5 mx-auto max-w-[490px] lg:max-w-auto">
                        <Form pageContext={pageContext} onSent={(isSent) => {
                            setSent(isSent)
                        }}/>
                    </div>
                </div>
            </div>

        </MainLayout>
    )
}

export default Contatti

export const Head = ({pageContext}) => (
    <SeoComponent pageContext={pageContext} />
)
