import * as React from "react";
import {useEffect} from "react";

const SeoComponent = ({pageContext}) => {
    useEffect(() => {
        document.documentElement.lang = pageContext.locale;
    }, [pageContext])
    return (
        <>
            <title>{pageContext.title}</title>
            <meta name="description" content={pageContext.description}/>
            <meta name="og:title" property="og:title" content={pageContext.title}/>
            <meta name="og:url" property="og:url" content={pageContext.canonicalUrl}/>
            <meta name="og:description" property="og:description" content={pageContext.description}/>
            <meta name="og:locale" property="og:locale" content={pageContext.locale}/>
            <meta name="language" property="language" content={pageContext.locale}/>
            <meta name="robots" content="index, follow"/>
            <meta name="googlebot" content="index, follow"/>
            <link rel="canonical" href={pageContext.canonicalUrl} key="canonical"/>
        </>
    )
}

export default SeoComponent