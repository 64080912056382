import * as React from 'react'
import loadable from "@loadable/component";
const Header = loadable(() => import('./partials/header'))
const Footer = loadable(() => import('./partials/footer'))
const CookieConsent = loadable(() => import('../context/cookieConsent'))

const MainLayout = ({pageContext, children}) => {
    return (
        <>
            <div className="min-h-screen bg-white">
                <div className="relative min-h-screen flex flex-col">
                    <Header/>
                    <div className="flex-1 mt-[66px] sm:mt-[70px] lg:mt-[86px]">
                        {children}
                    </div>
                    <Footer/>
                </div>
            </div>
            <CookieConsent />
        </>
    )
}
export default MainLayout
